import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Integration } from '../models/integration.model';
import { Observable } from 'rxjs';
import { IntegrationTypeEnum } from '../../public-api';
import { DataResultResponse } from '@trendbuild/trend-agendor-api/src/lib/models/base.types';
import { UsersObject } from '@trendbuild/trend-agendor-api/src/lib/models/users.types';

@Injectable({ providedIn: 'root' })
export class IntegrationService extends AbstractService<Integration> {

  getURLBase(): string {
    return '/integration';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Integration): void {
    delete obj?._id;
  }
  preUpdate(obj: Integration): void {
  }

  getListFiltered(search: string, type: IntegrationTypeEnum): Observable<Array<Integration>> {
    let url = '/list?1=1';
    if (!!search) {
      url += '&search=' + search;
    }
    if (!!type) {
      url += '&type=' + type;
    }
    return super.get(url);
  }

  getAssistantsByChannel(idChannel: string): Observable<Array<Integration>> {
    return super.get(`/assistants/channel/${idChannel}`);
  }

  getAgendorUsers(token: string): Observable<DataResultResponse<UsersObject>> {
    return super.get(`/agendor/users/${token}`);
  }

  deleteAssistant(integrationId: string,id: string): Observable<void> {
    return super.delete(`assistant/${integrationId}/${id}`);
  }

}  