import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService, Attendance } from '../../../../public-api';
import { Pager } from '../../../commons/pager';
import { Category } from '../../../models/category.model';
import { BehaviorSubject } from 'rxjs';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'modal-response-fast',
  templateUrl: './modal-response-attendance.component.html',
  styleUrls: ['./modal-response-attendance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalResponseAttendanceComponent {
  listCategory: Category[] = [];

  pager: Pager<Attendance> = new Pager<Attendance>({ perPage: 10 });
  messageStatus: any;
  attendaceStatus: any;
  searchTerm = '';
  originalList: any[];
  loadingSpinnerSubject = new BehaviorSubject<boolean>(true);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialogRef: MatDialogRef<ModalResponseAttendanceComponent>,
    private dashboardService: DashboardService,
    private alertService: AlertService,
  ) {

  }

  ngOnInit(): void {
    this.getList();
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  getList(): void {
    const statusMessage = [{
      message: 'Atendimentos em andamento',
      status: 'IN_PROGRESS'
    }, {
      message: 'Atendimentos fora do horário',
      status: 'OVERTIME'
    }, {
      message: 'Atendimentos concluídos',
      status: 'CLOSED'
    }, {
      message: 'Atendimentos em aguardando',
      status: 'PENDING',
    }, {
      message: 'Atendimentos pausados',
      status: 'PAUSED',
    }];

    this.loadingSpinnerSubject.next(true);
    this.messageStatus = statusMessage.find((x) => x.status === this.data.status);
    this.dashboardService.getStatusAttendance(
      this.data.status,
      this.data.dateStart,
      this.data.dateEnd,
      this.data.department,
      this.data.attendant,
      this.data.channel,
      this.pager.page,
      this.pager.perPage
    ).subscribe({
      next: (value: any) => {
        this.pager = value;
        this.originalList = value.list;
      },
      complete: () => this.loadingSpinnerSubject.next(false),
    });
  }

  onSearch(term: string) {
    this.searchTerm = term;
    this.pager.list = this.pager.list.filter(item => {
      const searchText = term.toLowerCase();
      return (
        item.contact?.name?.toLowerCase().includes(searchText) ||
        item.channel?.name?.toLowerCase().includes(searchText) ||
        item.department?.name?.toLowerCase().includes(searchText)
      );
    });

    if (term.trim() === '') {
      this.pager.list = this.originalList;
    } else {
      this.pager.list = this.pager.list.filter(item => {
        const searchText = term.toLowerCase();
        return (
          item.contact?.name?.toLowerCase().includes(searchText) ||
          item.channel?.name?.toLowerCase().includes(searchText) ||
          item.department?.name?.toLowerCase().includes(searchText)
        );
      });
    }
  }
}