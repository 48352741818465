<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Alterar Módulo Carteira de Clientes</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados do módulo arteira de Clientes</h6>

  <form [formGroup]="formGroup" style="max-height: 465px; overflow-y: auto; overflow-x: hidden; padding: 5px;">
  <div class="flex flex-col flex-1 my-2">
    <div class="flex flex-col w-full mt-[15px]">
      <label class="font-medium text-black">Tempo limite sem resposta (em minutos)</label>
      <input class="input-field" formControlName="noResponseTimeoutCustomerPortfolio" [mask]="'0*'">
    </div>
    <field-error-component [field]="'noResponseTimeoutCustomerPortfolio'" [label]="'Tempo limite sem resposta (em minutos)'" [formGroup]="formGroup">
    </field-error-component>
  </div>
  </form>
</div>
<div class="flex justify-between items-center w-full gap-2 px-0 mt-4">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[50%]"
    (click)="save()">
    {{ !loading ? 'Salvar' : '' }}
    <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
  </button>
</div>