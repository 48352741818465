import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Category } from '../models/category.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CategoryService extends AbstractService<Category> {

  getURLBase(): string {
    return '/category';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Category): void {
    delete obj?._id;
  }

  preUpdate(obj: Category): void {
  }

  createCategory(obj: Category): Observable<Category> {
    return super.post('/create-category', obj);
  }

  override getList(name?: string): Observable<Category[]> {
    const params = name ? { params: { name } } : {};
    return this.http.get<Category[]>(this.getURLBase() + '/list', params);
  }

}
