import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, tap, throwError } from "rxjs";
import { AuthenticationService } from "../../services/authentication.service";
import { AlertService } from "../components/alert/alert.service";

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private alertService: AlertService, private router: Router, private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap({
        next: () => null,
        error: (err: HttpErrorResponse) => {

          let dsError = "";
          switch (err?.status) {
            case 0:
              // dsError = "Falha de comunicação com o servidor.";
              dsError = undefined; // "Falha de comunicação com o servidor.";
              break;

            case 400:
              dsError = err.error.message;
              break;

            case 401:
              dsError = "Usuário não possui permissão de acesso.";
              this.authenticationService.logout();
              window.location.href = '/login';
              break;

            case 403:
              dsError = err.error.message;
              this.authenticationService.logout();
              window.location.href = '/login';
              break;

            case 500:
              dsError = err.error.message;
              break;
          }

          if (!!dsError) {
            this.alertService.error(dsError);
          }

          return throwError(() => new Error(dsError));
        }
      })
    );
  }
}
