import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardMaxUsers implements CanActivate {
  private totalUsers: number = Number(localStorage.getItem('currentTotalUsers'));
  private maxUsers: number = Number(localStorage.getItem('currentMaxUsersCompany'));

  constructor(private router: Router) { 
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.totalUsers >= this.maxUsers) {
      this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    return true;
  }

}
