import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { AbstractComponent } from '../../../commons/abstract.component';
import { Channel } from '../../../models/channel.model';
import { Integration } from '../../../models/integration.model';
import { SettingsModule } from '../../../models/settings-module.model';
import { IntegrationService } from '../../../services/integration.service';
import { SettingsModuleService } from '../../../services/settings-module.service';
import { CSATModuleEditComponent } from './csat-module-edit/csat-module-edit.component';
import { CustomerPortfolioModuleEditComponent } from './customer-portfolio-module-edit/customer-portfolio-module-edit.component';

@Component({
  selector: 'settings-module-component',
  templateUrl: './settings-module.component.html',
  styleUrls: ['settings-module.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsModuleComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {

  @Input() channel!: Channel;

  integrations: Integration[];
  settingsModule: SettingsModule = {} as SettingsModule;

  constructor(
    injector: Injector,
    private integrationService: IntegrationService,
    private settingsModuleService: SettingsModuleService,
    public dialog: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.createForm();
    this.getIntegrations();
    this.settingsModule = {} as SettingsModule;
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.settingsModule, value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['channel']?.currentValue) {
      this.settingsModule = {} as SettingsModule;
      this.channel = changes['channel']?.currentValue;
      this.getSettingsModule();
    }
  }

  override ngOnDestroy(): void {
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      activeCSATModule: [false],
      activeIAModule: [false],
      activeWavoipModule: [false],
      assistant: [''],
      integration: [''],
      activeCustomerPortfolioModule: [false],
      noResponseTimeoutCustomerPortfolio: [null],
      noResponseTimeoutCSAT: [null],
    });
    if (!this.getModuleCSATCompanyCurrentUser()) {
      this.formGroup.get('activeCSATModule').setValue(false);
      this.formGroup.get('activeCSATModule')?.disable();
    }
    if (!this.getModuleIACompanyCurrentUser()) {
      this.formGroup.get('activeIAModule').setValue(false);
      this.formGroup.get('activeIAModule')?.disable();
    }
    if (!this.getModuleWAVoipCompanyCurrentUser()) {
      this.formGroup.get('activeWavoipModule').setValue(false);
      this.formGroup.get('activeWavoipModule')?.disable();
    }
    if (!this.getModuleCustomerPortfolioCompanyCurrentUser()) {
      this.formGroup.get('activeCustomerPortfolioModule').setValue(false);
      this.formGroup.get('activeCustomerPortfolioModule')?.disable();
    }
    if (!this.isAdmin()) {
      this.formGroup.disable();
    }
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.settingsModule, value);
    });
  }

  getSettingsModule() {
    this.settingsModuleService.getByChannel(this.channel._id).subscribe({
      next: (value: SettingsModule) => {
        if (value) {
          this.settingsModule = value;
          this.formGroup.patchValue(this.settingsModule);
        } else {
          this.formGroup.get('activeCSATModule').setValue(false);
          this.formGroup.get('activeIAModule').setValue(false);
          this.formGroup.get('activeWavoipModule').setValue(false);
          this.formGroup.get('activeCustomerPortfolioModule').setValue(false);
          this.settingsModule.channel = this.channel;
        }
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  isCSATModuleActive() {
    return this.formGroup.get('activeCSATModule').value;
  }

  isIAModuleActive() {
    return this.formGroup.get('activeIAModule').value;
  }

  isWAVoipModuleActive() {
    return this.formGroup.get('activeWavoipModule').value;
  }

  isCustomerPortfolioModuleActive() {
    return this.formGroup.get('activeCustomerPortfolioModule').value;
  }

  editCSAT() {
    if (this.isAdmin()) {
      const dialogRef = this.dialog.open(CSATModuleEditComponent, {
        width: '600px',
        data: this.settingsModule
      });

      dialogRef.afterClosed().subscribe(result => {
        this.getSettingsModule();
      });
    }
  }

  editCustomerPortfolio() {
    if (this.isAdmin()) {
      const dialogRef = this.dialog.open(CustomerPortfolioModuleEditComponent, {
        width: '600px',
        data: this.settingsModule
      });

      dialogRef.afterClosed().subscribe(result => {
        this.getSettingsModule();
      });
    }
  }

  onConfirmClick() {
    const activeIAModule = this.formGroup.get('activeIAModule').value;
    const assistantValue = this.formGroup.get('assistant').value;
    if (activeIAModule && (assistantValue === null || assistantValue === '')) {
      this.alertService.warning('Selecione um assistente');
      return;
    }
    this.settingsModuleService.update(this.settingsModule._id, this.settingsModule).subscribe({
      next: (value: SettingsModule) => {
        this.alertService.success('Configurações dos módulos atualizadas com sucesso.');
      },
      error: (err) => {
        this.alertService.error(err.error.message);
      },
    });
  }

  getIntegrations(): void {
    this.integrationService.getAssistantsByChannel(this.channel._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (integrations: Integration[]) => {
          this.integrations = integrations;
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  onSelectAssistant(idIntegration: string, selectedAssistantId: string): void {
    console.log('onSelectAssistant', idIntegration, selectedAssistantId);
    this.formGroup.patchValue({
      assistant: selectedAssistantId,
      integration: idIntegration
    });
  }

  onToggleCustomerPortfolioModuleChange(): void {
    if (this.isCustomerPortfolioModuleActive()) {
      if (!this.formGroup.get('noResponseTimeoutCustomerPortfolio').value) {
        this.formGroup.get('noResponseTimeoutCustomerPortfolio').setValue(5);
      }
    }
  }

  onToggleCSATModuleChange(): void {
    if (this.isCSATModuleActive()) {
      if (!this.formGroup.get('noResponseTimeoutCSAT').value) {
        this.formGroup.get('noResponseTimeoutCSAT').setValue(5);
      }
    }
  }

}
