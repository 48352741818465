import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Channel, Company, Message, Note, Pager, SearchParams, SettingsModule, SimpleListsFilter, Tag, User } from '../../public-api';
import { AbstractService } from '../commons/abstract.service';
import { Attendance, Attendances } from '../models/attendance.model';

export type CacheControlType = 'AUTO' | 'CACHED';

@Injectable({ providedIn: 'root' })
export class CacheService extends AbstractService<Attendance> {

  getURLBase(): string {
    return '/cached';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Attendance): void {
    delete obj?._id;
  }

  preUpdate(obj: Attendance): void {
  }

  getUserById(idUser: string): Observable<User> {
    return super.get(`/user/${idUser}`);
  }

  getCompanyById(idCompany: string): Observable<Company> {
    return super.get(`/company/${idCompany}`);
  }

  getChannelByCompany(idCompany: string): Observable<Channel> {
    return super.get(`/channels/${idCompany}`);
  }

  getAttendances(idCompany: string, page: number, perPage: number, search?: string, params?: Partial<SearchParams>): Observable<Attendances> {
    const baseURL = `/attendances/${idCompany}`;
    const pagering = `${baseURL}?page=${page}&perPage=${perPage}`;

    const getSearchConfig = (search: string): string => {
      if (!!search) {
        if (search.includes('+')) {
          const searches = search.split('+');
          let urlingSerches = '';
          searches.forEach((search) => {
            urlingSerches += `&search=${search}`;
          });
          return urlingSerches;
        } else {
          return '&search=' + search;
        }
      } else {
        return '';
      }
    }
    const searching = `${pagering}${getSearchConfig(search)}`;

    let urling = `${searching}`;
    if (!!params) {
      for (let param in params) {
        urling = `${urling}&${param}=${params[param]}`;
      }
    }
    return super.get(urling);
  }

  getMessagesByAttendance(idCompany: string, idAttendance: string, page: number, perPage: number, search?: string, params?: Partial<SearchParams>): Observable<Pager<Message>> {
    const baseURL = `/messages/${idCompany}/${idAttendance}`;
    const pagering = `${baseURL}?page=${page}&perPage=${perPage}`;

    const getSearchConfig = (search: string): string => {
      if (!!search) {
        if (search.includes('+')) {
          const searches = search.split('+');
          let urlingSerches = '';
          searches.forEach((search) => {
            urlingSerches += `&search=${search}`;
          });
          return urlingSerches;
        } else {
          return '&search=' + search;
        }
      } else {
        return '';
      }
    }
    const searching = `${pagering}${getSearchConfig(search)}`;

    let urling = `${searching}`;
    if (!!params) {
      for (let param in params) {
        urling = `${urling}&${param}=${params[param]}`;
      }
    }
    return super.get(urling);
  }

  getAttendanceById(idAttendance: string): Observable<Attendance> {
    return super.get(`/attendance/${idAttendance}`);
  }

  getNotesByAttandence(idAttendance: string, idContact: string): Observable<Array<Note>> {
    return super.get(`/notes/${idAttendance}/${idContact}`);
  }

  getTagsByCompany(idCompany: string): Observable<Array<Tag>> {
    return super.get(`/tags/${idCompany}`);
  }

  getSimpleListsFiltersByCompany(idCompany: string): Observable<SimpleListsFilter> {
    return super.get(`/get/simple/lists/filters/${idCompany}`);
  }

  getSettingsModulesByCompany(idCompany: string): Observable<Array<SettingsModule>> {
    return super.get(`/settings-modules/${idCompany}`);
  }

}
