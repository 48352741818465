import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable, Injector } from "@angular/core";
import { catchError, Observable, throwError } from 'rxjs';
import { AlertService } from '../shared/components/alert/alert.service';
import { CONSTANTS, Constants, ENV, Environment } from './injection.token';

@Injectable()
export class CustomHttp extends HttpClient {

  private env: Environment;
  private constants: Constants;
  private alertService: AlertService;

  constructor(handler: HttpHandler, injector: Injector) {
    super(handler);
    this.env = injector.get(ENV);
    this.constants = injector.get(CONSTANTS);
    this.alertService = injector.get(AlertService);
  }

  private handleUrl(url: string): string {
    return url?.startsWith('/cached') ? this.env['trendCacheUrl'] + url : this.env['apiUrl'] + url;
  }

  override get(url: string, options: any): Observable<any> {
    return super.get(this.handleUrl(url), options).pipe(catchError(this.handleError));
  }

  override post(url: string, body: string, options: any): Observable<any> {
    return super.post(this.handleUrl(url), body, options).pipe(catchError(this.handleError));
  }

  override put(url: string, body: string, options: any): Observable<any> {
    return super.put(this.handleUrl(url), body, options).pipe(catchError(this.handleError));
  }

  override delete(url: string, options: any): Observable<any> {
    return super.delete(this.handleUrl(url), options).pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    if (error.status === 401) {
      localStorage.removeItem(this.constants['currentUser']);
      window.location.href = '/login';
      this.alertService.error(error.message);
    }
    return throwError(() => (error))
  }
}

export function customHttpFactory(handler: HttpHandler, injector: Injector): HttpClient {
  return new CustomHttp(handler, injector);
}

export let customHttpProvider = {
  provide: HttpClient,
  useFactory: customHttpFactory,
  deps: [HttpHandler, Injector]
};
