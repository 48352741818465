import { Injectable, Injector, OnDestroy } from '@angular/core';
import WavoipInstance from "wavoip-api";
import { AbstractComponent } from '../../commons/abstract.component';

@Injectable({ providedIn: 'root' })
export class WavoipService extends AbstractComponent implements OnDestroy {
  private device = new WavoipInstance();
  private wavoip = null;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  async startDevice(token: string): Promise<void> {
    const wppInstance = await this.device.connect(token);

    wppInstance.socket.io.on("error", () => {
      this.alertService.error('Ops! Não é possível fazer a ligação no momento.');
    });

    this.wavoip = wppInstance;
  }

  async startCall(phone: string): Promise<void> {
    if (!!this.wavoip) {
      await this.wavoip.callStart({
        whatsappid: phone,
      });
    } else {
      this.alertService.info('Ops! Não foi possível fazer a ligação.');
    }
  }

  async endCall(): Promise<void> {
    await this.wavoip.endCall();
  }
}