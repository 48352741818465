/**
 * abstract.services.ts
 */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchParams } from './abstract.types';
import { Pager } from './pager';

export abstract class AbstractService<T> {

  private sHttp: HttpClient;

  constructor(http: HttpClient) {
    this.sHttp = http;
  }

  abstract getURLBase(): string;

  get(path: string): Observable<any> {
    return this.sHttp.get(this.getURLBase() + path);
  }

  post(path: string, obj: any, config = {}): Observable<any> {
    return this.sHttp.post(this.getURLBase() + path, obj, config);
  }

  put(path: string, obj: any): Observable<any> {
    return this.sHttp.put(this.getURLBase() + path, obj);
  }

  patch(path: string, obj?: any): Observable<any> {
    return this.sHttp.patch(this.getURLBase() + path, obj);
  }

  getList(): Observable<Array<T>> {
    return this.sHttp.get<Array<T>>(this.getURLBase() + '/list');
  }

  getAll(page: number, perPage: number, search?: string, params?: Partial<SearchParams>): Observable<Pager<T>> {
    const baseURL = `${this.getURLBase()}/pager`;
    const pagering = `${baseURL}?page=${page}&perPage=${perPage}`;

    const getSearchConfig = (search: string): string => {
      if (!!search) {
        return '&search=' + encodeURIComponent(search);
      } else {
        return '';
      }
    }
    const searching = `${pagering}${getSearchConfig(search)}`;

    let urling = `${searching}`;
    if (!!params) {
      for (let param in params) {
        urling = `${urling}&${param}=${params[param]}`;
      }
    }
    return this.sHttp.get<Pager<T>>(urling);
  }

  getById(id: string): Observable<T> {
    return this.sHttp.get<T>(this.getURLBase() + '/' + id);
  }

  abstract preCreate(obj: T): void;
  create(obj: T): Observable<T> {
    this.preCreate(obj);
    return this.sHttp.post<T>(this.getURLBase() + '/create', obj);
  }

  abstract preUpdate(obj: T): void;
  update(_id: string, obj: T): Observable<T> {
    this.preUpdate(obj);
    return this.sHttp.put<T>(this.getURLBase() + '/update/' + _id, obj);
  }

  delete(id: string): Observable<void> {
    return this.sHttp.delete<void>(this.getURLBase() + '/' + id);
  }

  getWithParams(path: string, queryParams: any): Observable<any> {
    const params = new HttpParams({ fromObject: queryParams });
    return this.sHttp.get<any>(this.getURLBase() + path, { params });
  }
}