<div class="card-hover-wrapper">
  <mat-card-content>
    <div class="flex gap-2 items-center">
      <div class="card-graphic-small-content">
        <span class="card-total text-base">{{total}}</span>
        <span class="text-xs text-mutted mt-10 white-space">{{label}}</span>
      </div>
      <div>
        <div *ngIf="graphicType && graphicType==='bar'">
          <ngx-charts-bar-vertical [view]="[200,230]" [results]="single" [gradient]="gradient" [xAxis]="showXAxis"
            [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [doughnut]="true"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical>
        </div>
        <div class="" *ngIf="graphicType && graphicType==='doughnut'">
          <ngx-charts-pie-chart [view]="[200,230]" [legend]="false" [labels]="false" [legendTitle]="''"
            [view]="[200,230]" [results]="single" [doughnut]="true">
          </ngx-charts-pie-chart>
        </div>
      </div>
    </div>

    <div *ngIf="graphicType && graphicType==='percentage'">
      <card-percentage-chart-component [percentage]="contactInfoChart" [radius]="99" [textColor]="textColor"
        [datasetTitle]="datasetTitle" />
    </div>
  </mat-card-content>
</div>