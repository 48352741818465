import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent } from '../../../commons/abstract.component';
import { WavoipService } from '../../../services/wavoip/wavoip.service';
import { Contact } from '../../../models/contact.model';

@Component({
    selector: 'call-phone-component',
    templateUrl: './call-phone.component.html',
    styleUrls: ['./call-phone.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CallPhoneComponent extends AbstractComponent {
    private intervalId: any;

    duration: number = 0;
    calling: boolean = false;

    constructor(
        injector: Injector,
        private _matDialogRef: MatDialogRef<CallPhoneComponent>,
        private wavoipService: WavoipService,
        @Inject(MAT_DIALOG_DATA) public data: Contact,
    ) {
        super(injector);
        this.startWavoip();
    }

    ngOnInit(): void {
    }

    override ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        super.ngOnDestroy();
    }

    private async startWavoip(): Promise<void> {
        // @TODO: Mock token
        // await this.wavoipService.startDevice('51c56a8d-b7ba-4416-b77b-ed946ac1aba8'); // token canal do suporte
        await this.wavoipService.startDevice('b1ee0760-5c84-42c0-b9d4-3109bc3b3242'); // token canal do comercial
    }

    public async startCallWavoip(): Promise<void> {
        if (!!this.data) {
            this.calling = true;
            this.duration = 0;
            await this.wavoipService.startCall(this.data.phone);

            this.intervalId = setInterval(() => {
                this.duration++;
            }, 1000);
        } else {
            this.calling = false;
            this.alertService.info('Contato não encontrado!');
        }
    }

    public async endCallWavoip(): Promise<void> {
        this.calling = false;
        await this.wavoipService.endCall();

        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    async onClose(): Promise<void> {
        if (this.calling) {
            await this.endCallWavoip();
        }

        this.calling = false;
        this._matDialogRef.close();
    }

    formatTimeCall(): string {
        const hours = Math.floor(this.duration / 3600);
        const minutes = Math.floor((this.duration % 3600) / 60);
        const seconds = this.duration % 60;

        const time = (num: number) => (num < 10 ? `0${num}` : num);

        return `${time(hours)}:${time(minutes)}:${time(seconds)}`
    }
}