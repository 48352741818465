import { ChangeDetectorRef, Component, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent } from '../../../../commons/abstract.component';
import { Channel } from '../../../../models/channel.model';
import { SettingsModule } from '../../../../models/settings-module.model';
import { SettingsModuleService } from '../../../../services/settings-module.service';

@Component({
  selector: 'customer-portfolio-module-edit-component',
  templateUrl: './customer-portfolio-module-edit.component.html',
  styleUrls: ['./customer-portfolio-module-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerPortfolioModuleEditComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {

  @Input() channel!: Channel;

  settingsModule: SettingsModule = {} as SettingsModule;

  constructor(
    injector: Injector,
    private service: SettingsModuleService,
    public dialogRef: MatDialogRef<CustomerPortfolioModuleEditComponent>,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: SettingsModule,
  ) {
    super(injector);
    this.createForm();
  }

  ngOnInit(): void {
    this.createForm();
    this.settingsModule = {} as SettingsModule;
    this.formGroup.patchValue(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  override ngOnDestroy(): void {
  }

  getSettingsModule() {
    this.service.getByChannel(this.channel._id).subscribe({
      next: (value: SettingsModule) => {
        if(value) {
          this.settingsModule = value;
          this.formGroup.patchValue(this.settingsModule);
        }
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      _id: [null],
      activeCustomerPortfolioModule: [false],
      noResponseTimeoutCustomerPortfolio: [null, Validators.required],
      channel: [null, Validators.required]
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.settingsModule, value);
    });
  }

  isCustomerPortfolioModuleActive() {
    return this.formGroup.get('activeCustomerPortfolioModule')?.value;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.settingsModule._id) {
      this.service.update(this.settingsModule._id, this.settingsModule).subscribe({
        next: (value: SettingsModule) => {
          this.alertService.success('Configurações do módulo Carteira de Clientes atualizadas com sucesso.');
          this.dialogRef.close();
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    } else {
      this.service.create(this.settingsModule).subscribe({
        next: (value: SettingsModule) => {
          this.settingsModule = value;
          this.formGroup.patchValue(this.settingsModule);
          this.alertService.success('Configurações dos módulo Carteira de Clientes atualizadas com sucesso.');
          this.dialogRef.close();
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    }
  }
}