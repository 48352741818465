import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Observable } from 'rxjs';
import { SettingsAttendance } from '../models/settings-attendance.model';

@Injectable({ providedIn: 'root' })
export class SettingsAttendanceService extends AbstractService<SettingsAttendance> {

  getURLBase(): string {
    return '/settings-attendance';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: SettingsAttendance): void {
    delete obj?._id;
  }

  preUpdate(obj: SettingsAttendance): void {
  }

  getByChannel(idChannel: string): Observable<SettingsAttendance> {
    return super.get(`/find/channel/${idChannel}`);
  }
  
}
