import { TemplateMessageObject } from "@trendbuild/trend-cloud-api";
import { Pager } from "../commons/pager";
import { Base } from "./base.model";
import { Channel } from "./channel.model";
import { Company } from "./company.model";
import { Contact } from "./contact.model";
import { Department } from "./department.model";
import { AttendanceStatusEnum } from "./enum/attendance.status.enum";
import { Message } from "./message.model";
import { Tag } from "./tag.model";
import { User } from "./user.model";

export interface Attendance extends Base {
  protocol: string;
  contact: Contact;
  endDate: Date;
  company: Company;
  channel: Channel;
  user: User;
  lastMessage: Message;
  department: Department;
  status: AttendanceStatusEnum;
  tags: Tag[];
  inConversation: boolean;
  expiredAt: Date;
  countUnreadMessages: number;
  chatbotStatus: {
    navPath: string;
    automated: boolean;
    running: boolean;
    enabled: boolean;
    expiredAt: Date;
  };
  metadata: {
    integrations: {
      ai: {
        name: string;
        actived: boolean;
        assistant: string;
        integration: string;
        openai: {
          threadId: string;
        }
      }
    };
  }
  kpis: KpisTypes;
  closedAt: Date;
  feedbackCSAT: number;
  isDirectedByCustomerPortfolio: boolean;
}

export interface KpisTypes {
  tmfh: number;
  tme: number;
  tma: number;
  tmp: number;
  tmta: number;
};

export interface CreateAttendanceFromTemplate {
  idDepartment: string,
  idChannel: string,
  idContact: string,
  template: TemplateMessageObject,
  headerParams: string[],
  bodyParams: string[],
  previewText: string
}

export interface Attendances {
  counts: Array<CountState>,
  pager: Pager<Attendance>
}

export interface CountState {
  status: AttendanceStatusEnum;
  count: number;
}


