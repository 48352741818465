import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { UtilHelper } from '../../../../helpers/util.helper';
import { ResponseTypeEnum } from '../../../../models/enum/response.type';
import { AbstractComponent } from '../../../../commons/abstract.component';
import { Pager } from '../../../../commons/pager';
import { Category } from '../../../../models/category.model';
import { Response } from '../../../../models/response.model';
import { CategoryService } from '../../../../services/category.service';
import { ResponseService } from '../../../../services/response.service';
import { PreviewMediaComponent, PreviewMediaData } from '../../preview-media/preview-media.component';

@Component({
  selector: 'bottom-sheet-response-fast',
  templateUrl: './bottom-sheet-response-fast.component.html',
  styleUrls: ['./bottom-sheet-response-fast.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BottomSheetResponseFastComponent extends AbstractComponent {
  listCategory: Array<Category> = new Array<Category>();

  pager: Pager<Response> = new Pager<Response>({ perPage: 4 });

  loadingSpinnerSubject = new BehaviorSubject<boolean>(true);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  isTableResponseApp: boolean;

  constructor(
    injector: Injector,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetResponseFastComponent>,
    private categoryService: CategoryService,
    private responseService: ResponseService,
    private _modalPreview: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getList(this.searchString);
    this.getListCategory();
  }

  search(term: string): void {
    this.getList(term);
  }

  selectedResponseFast(response: Response): void {
    if (response.midia) {
      const dialogRefPreview = this._modalPreview.open(PreviewMediaComponent, {
        width: '600px',
        data: <PreviewMediaData>{
          response,
          midia: response.midia,
          message: response.message,
          isTableResponseApp: true
        }
      });

      dialogRefPreview.afterClosed().subscribe((result: PreviewMediaData) => {
        if (result) {
          this._bottomSheetRef.dismiss(result);
        }
      });
    } else {
      this._bottomSheetRef.dismiss(response);
    }
  }

  selectedCategory(category: string): void {
    this.getList(category);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList(this.searchString);
  }

  getList(termSearch: string): void {
    this.loadingSpinnerSubject.next(true);

    this.searchParams = {
      company: this.getIDCurrentCompany(),
      type: ResponseTypeEnum.QUICK,
    };

    this.responseService.getAll(this.pager.page, this.pager.perPage, termSearch, this.searchParams).subscribe({
      next: (value: Pager<Response>) => {
        this.pager = value;
      },
      complete: () => this.loadingSpinnerSubject.next(false),
    });
  }

  private getListCategory(): void {
    this.categoryService.getList().subscribe({
      next: (value) => {
        this.listCategory = value;
      },
    });
  }

  public getFileExtension(item: Response): string {
    if (item.vcardContact) {
      return 'Contato';
    }
    if (item.place) {
      return 'Localização';
    }
    return UtilHelper.getFileExtension(item.contentType);
  }


}