<div class="flex w-full flex-col items-start gap-2">
  <div class="flex justify-between w-full">
    <div class="w-14 h-14 flex cursor-pointer items-center justify-center rounded-full hover:bg-[#f2f2f3]"
      (click)="onClose()">
      <mat-icon class="text-black">clear</mat-icon>
    </div>
  </div>

  <div *ngIf="calling" class="w-full text-center my-4">
    <span class="text-2xl font-mono">{{ formatTimeCall() }}</span>
  </div>

  <h1 class="font-black text-[20px] justify-center text-black mt-2.5">
    Fazer ligação para - {{data.name}}
  </h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">
    Faça uma ligação para o número {{data.phone}}
  </h6>
</div>
<button *ngIf="!calling"
  class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg border-2 bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]"
  (click)="startCallWavoip()">Ligar</button>

<button *ngIf="calling"
  class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
  (click)="endCallWavoip()">Encerrar</button>