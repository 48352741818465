import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Observable } from 'rxjs';
import { Attendance } from '../models/attendance.model';

export interface ResponseFiltersAttendanceTypes {
  dateStartCurrent: string;
  dateEndCurrent: string;
  dateStartPrevious: string;
  dateEndPrevious: string;
  department?: string;
  attendant?: string;
  channel?: string;
  feedbackCSAT?: string;
  page?: string;
  limit?: string;
  data?: string;
  type?: string;
};

@Injectable({ providedIn: 'root' })
export class ReportService extends AbstractService<Attendance> {

  getURLBase(): string {
    return '/report';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Attendance): void {
    delete obj?._id;
  }

  preUpdate(obj: Attendance): void {
  }

  getByAttendanceCountItems(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      channel: forms.channel,
      feedbackCSAT: forms.feedbackCSAT
    });
    return super.get(`/performace?${params.toString()}`);
  }

  getByAttendanceCountItemsPerformece(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      channel: forms.channel,
      feedbackCSAT: forms.feedbackCSAT,
      page: forms.page,
      limit: forms.limit
    });
    return super.get(`/performace/list?${params.toString()}`);
  }

  getAttendanceItemsPerformeceExport(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      feedbackCSAT: forms.feedbackCSAT,
      channel: forms.channel
    });
    return super.get(`/performace/list/export?${params.toString()}`);
  }

  getAttendanceCard(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const dataValue: string = forms.type == 'leadCurrent'|| forms.type == 'leadPrevious' ? '' : JSON.stringify(forms.data)
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      channel: forms.channel,
      feedbackCSAT: forms.feedbackCSAT,
      page: forms.page,
      limit: forms.limit,
      data: dataValue,
      type: forms.type
    });
    return super.get(`/performace/list/item?${params.toString()}`);
  }


  getGraphicChannelAttendance(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      feedbackCSAT: forms.feedbackCSAT,
      channel: forms.channel
    });
    return super.get(`/performace/graphic/channel?${params.toString()}`);
  }

  getGraphicAttendantAttendance(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      feedbackCSAT: forms.feedbackCSAT,
      channel: forms.channel
    });
    return super.get(`/performace/graphic/attendant?${params.toString()}`);
  }

  getGraphicDepartmentAttendance(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      feedbackCSAT: forms.feedbackCSAT,
      channel: forms.channel
    });
    return super.get(`/performace/graphic/department?${params.toString()}`);
  }

  getCountContactItems(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      feedbackCSAT: forms.feedbackCSAT,
      channel: forms.channel
    });
    return super.get(`/contact?${params.toString()}`);
  }

  getAttendanceCardContact(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const dataValue: string = forms.type == 'leadCurrent'|| forms.type == 'leadPrevious' ? '' : JSON.stringify(forms.data)
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      channel: forms.channel,
      feedbackCSAT: forms.feedbackCSAT,
      page: forms.page,
      limit: forms.limit,
      data: dataValue,
      type: forms.type
    });
    return super.get(`/contact/list/item?${params.toString()}`);
  }

  getAttendanceItemsContact(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      channel: forms.channel,
      feedbackCSAT: forms.feedbackCSAT,
      page: forms.page,
      limit: forms.limit
    });
    return super.get(`/contact/list?${params.toString()}`);
  }

  getGraphicChannelContact(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      channel: forms.channel,
      feedbackCSAT: forms.feedbackCSAT
    });
    return super.get(`/contact/graphic/channel?${params.toString()}`);
  }

  getGraphicAttendantContact(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      channel: forms.channel,
      feedbackCSAT: forms.feedbackCSAT
    });
    return super.get(`/contact/graphic/attendant?${params.toString()}`);
  }

  getGraphicDepartmentContact(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      channel: forms.channel,
      feedbackCSAT: forms.feedbackCSAT
    });
    return super.get(`/contact/graphic/department?${params.toString()}`);
  }

  getItemsContactExport(forms: ResponseFiltersAttendanceTypes): Observable<any> {
    const params = new URLSearchParams({
      dateStartCurrent: forms.dateStartCurrent,
      dateEndCurrent: forms.dateEndCurrent,
      dateStartPrevious: forms.dateStartPrevious,
      dateEndPrevious: forms.dateEndPrevious,
      department: forms.department,
      attendant: forms.attendant,
      channel: forms.channel,
      feedbackCSAT: forms.feedbackCSAT
    });
    return super.get(`/contact/list/export?${params.toString()}`);
  }
}
